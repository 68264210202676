import { TEmployee } from "../AboutUsPage.hooks";

export const getNamePosition = (
  arr: TEmployee[],
  index: number,
  currentNamePosition: string
): string => {
  if (index === 0) return currentNamePosition;

  return arr[index - 1].teamPosition.namePosition !== currentNamePosition
    ? currentNamePosition
    : "";
};
