import { useStaticQuery, graphql } from "gatsby";

export const useFetchAboutUsContent = () => {
  const data: TData = useStaticQuery(graphql`
    {
      allStrapiAboutUsPage {
        edges {
          node {
            team {
              title
              image {
                localFile {
                  publicUrl
                }
                alternativeText
              }
              isShow
              employee {
                id
                fullName
                image {
                  localFile {
                    publicUrl
                  }
                  alternativeText
                }
                teamPosition {
                  namePosition
                }
              }
            }
            repetedContent {
              title
              id
              image {
                localFile {
                  publicUrl
                }
                alternativeText
              }
              description
            }
          }
        }
      }
    }
  `);

  return { data };
};

type TData = {
  allStrapiAboutUsPage: {
    edges: {
      node: {
        team: {
          title: string;
          image: {
            localFile: {
              publicUrl: string;
            };
            alternativeText: string;
          };
          isShow: boolean;
          employee: TEmployee[];
        };
        repetedContent: {
          title: string;
          id: string;
          image: {
            localFile: {
              publicUrl: string;
            };
            alternativeText: string;
          };
          description: string;
        }[];
      };
    }[];
  };
};

export type TEmployee = {
  id: string;
  fullName: string;
  image: {
    localFile: {
      publicUrl: string;
    };
    alternativeText: string;
  };
  teamPosition: {
    namePosition: string;
  };
};
