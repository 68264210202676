import React from "react";
import { Row, Container, Col } from "react-bootstrap";
import { useFetchAboutUsContent } from "../AboutUsPage.hooks";
import * as S from "./Content.styles";
import * as SH from "@shared";
import ReactHtmlParser from "react-html-parser";

export const Content = () => {
  const { data } = useFetchAboutUsContent();

  return (
    <SH.Wrapper.Primary>
      <Container>
        <Row className="justify-content-center overfow-hidden">
          <Col xs="12" xl="11">
            {data.allStrapiAboutUsPage.edges[0].node.repetedContent.map(
              ({ title, description, id, image }) => (
                <S.WrapperBox key={id}>
                  <Row className="justify-content-between row-box h-100">
                    <Col lg="6">
                      <SH.Text.Header2 className="pb-5">
                        {title}
                      </SH.Text.Header2>
                      <SH.Text.MarkdownTextBody>
                        {ReactHtmlParser(description)}
                      </SH.Text.MarkdownTextBody>
                    </Col>
                    <Col
                      className="d-flex flex-column justify-content-center mt-5 mt-lg-0"
                      lg="6"
                    >
                      <SH.Image
                        heightImg={heightImage}
                        src={image?.localFile?.publicUrl}
                        alt={image?.alternativeText}
                      />
                    </Col>
                  </Row>
                </S.WrapperBox>
              )
            )}
          </Col>
        </Row>
      </Container>
    </SH.Wrapper.Primary>
  );
};
const heightComponent = {
  desktop: 505,
  mobile: 885,
};

const heightImage = {
  desktop: heightComponent.desktop,
  mobile: 300,
};
