import styled, { css } from "styled-components";
import * as theme from "@theme";
import * as SH from "@shared";

export const WrapperBox = styled(SH.Wrapper.Box)`
  min-height: min-content;
  height: 885px;
  &:nth-of-type(even) {
    background-color: ${theme.colors.white};
  }
  @media ${theme.breakpoints.desktop} {
    height: 545px;
  }
`;

export const Image = styled(SH.Image)``;
