import React from "react";
import { Row, Container, Col } from "react-bootstrap";
import { useFetchAboutUsContent } from "../AboutUsPage.hooks";
import { getNamePosition } from "./OurTeam.utils";
import * as S from "./OurTeam.styles";
import * as SH from "@shared";

export const OurTeam = () => {
  const { data } = useFetchAboutUsContent();
  const team = data.allStrapiAboutUsPage.edges[0].node.team;

  return (
    <SH.Wrapper.Primary>
      <Container>
        <Row className="justify-content-center">
          <Col lg="10" className="px-0">
            <SH.Wrapper.Box
              className="justify-content-center d-flex flex-column justify-content-center"
              colorComponent="whitegray"
            >
              <SH.Text.Header2 className="text-center pb-5">
                {team?.title}
              </SH.Text.Header2>
              {team.image && (
                <SH.Image
                  heightImg={heightImg}
                  src={team.image.localFile.publicUrl}
                  alt={team.image?.alternativeText}
                />
              )}

              {team.isShow && (
                <S.ContainerGrid>
                  {!!team.employee.length &&
                    team.employee.map(
                      (
                        { fullName, image, teamPosition: { namePosition }, id },
                        index,
                        arr
                      ) => {
                        return (
                          <S.WrapperEmployee
                            key={id}
                            position={getNamePosition(arr, index, namePosition)}
                          >
                            <S.ImgEmployee src={image?.localFile.publicUrl} />
                            <S.NameEmployee>
                              {fullName && fullName}
                            </S.NameEmployee>
                          </S.WrapperEmployee>
                        );
                      }
                    )}
                </S.ContainerGrid>
              )}
            </SH.Wrapper.Box>
          </Col>
        </Row>
      </Container>
    </SH.Wrapper.Primary>
  );
};

const heightImg = {
  desktop: 400,
  mobile: 214.17,
};
