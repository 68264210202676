import styled, { css } from "styled-components";
import * as SH from "@shared";
import * as theme from "@theme";

export const ContainerGrid = styled.div`
  padding: 125px 0 50px 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(165px, 1fr));
  grid-gap: 15px;
  row-gap: 105px;
  @media ${theme.breakpoints.desktop} {
    grid-gap: 20px;
    row-gap: 125px;
  }
`;

export const WrapperEmployee = styled.div<{ position?: string }>`
  padding: 1rem;
  position: relative;

  &::before {
    content: "";
    display: block;
    padding-bottom: 100%;
  }

  ${({ position }) =>
    position &&
    css`
      &::after {
        content: "${position}";
        ${SH.Text.Header2Css};
        white-space: nowrap;
        position: absolute;
        top: -51px;
        display:block;
        text-align: center;
        color: ${theme.colors.darkblue}
      }
    `}
`;

export const ImgEmployee = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: block;
  border-radius: 50%;
  background-color: ${theme.colors.white};
`;

export const NameEmployee = styled.p`
  ${SH.Text.SubTitle2Css};
  color: ${theme.colors.lightblue};
  position: absolute;
  text-align: center;
  width: 100%;
  left: 0;
  bottom: -40px;
  white-space: nowrap;
`;
